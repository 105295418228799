import React from "react";
import ReactPlayer from "react-player";
import {motion} from 'framer-motion';
import styled from "styled-components";

const Wrapper = styled(motion.div)`
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 15px 15px #0005
`

const VideoPlayer = (props) => {
    return (
    <Wrapper>
        <ReactPlayer
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
            }}
            url={props.src}
            width='100%'
            height='100%'
            controls = {true}
        />
    </Wrapper>
    )
}

export default VideoPlayer;
